<template>
  <div>
    <h2>{{ $t("dashboard.title") }}</h2>
    <v-row>
      <v-col
        v-for="(item, idx) in listItems"
        :key="idx"
        :class="cardPadding"
        cols="12"
        :sm="leftMenu ? '12' : '6'"
        :md="leftMenu ? '12' : '4'"
      >
        <v-card
          class="d-flex elevation-3 rounded-sm pa-3 justify-space-between"
          active-class="selected-item"
          :class="[
            item.routePath == '' ? ['elevation-0', 'white', 'darken-1'] : ''
          ]"
          :to="item.routePath"
        >
          <div class="d-flex col-gap">
            <div class="d-flex align-self-center mr-1">
              <img
                height="44"
                :src="`/img_layout/dashboard/${item.icon}.svg`"
              />
            </div>
            <div
              class="text-body-0 primary--text font-weight-bold align-self-center flex-shrink-1"
              :class="{ 'py-3': !item.activeLabel && !leftMenu }"
            >
              {{ $t(item.title) }}
              <div v-if="item.promovar && showSwitch" class="switch-wraper">
                <v-switch
                  :label="
                    item.switchValue
                      ? $t(item.activeLabel)
                      : $t(item.inactiveLabel)
                  "
                  color="success"
                  dense
                  hide-details
                  class="pt-0 mt-0"
                  :input-value="item.switchValue"
                  :loading="item.switchLoading"
                ></v-switch>
              </div>
            </div>
          </div>
          <div
            v-if="item.activeLabel && leftMenu"
            class="d-flex dot flex-shrink-0"
            :class="item.switchValue ? 'success' : 'error'"
          ></div>
        </v-card>
      </v-col>
    </v-row>
    <h3 class="pt-6 pb-2 pb-sm-0">
      {{ $t("dashboard.needHelp") }}
    </h3>
    <v-row>
      <v-col
        v-for="(item, idx) in secondListItems"
        :key="idx"
        :class="cardPadding"
        cols="12"
        :sm="leftMenu ? '12' : '6'"
        :md="leftMenu ? '12' : '4'"
      >
        <v-card
          class="d-flex elevation-3 rounded-sm pa-3 col-gap"
          active-class="selected-item"
          :to="item.routePath"
        >
          <div class="d-flex">
            <img height="44" :src="`/img_layout/dashboard/${item.icon}.svg`" />
          </div>
          <div
            class="text-body-0 primary--text font-weight-bold align-self-center"
            :class="{ 'py-3': !item.activeLabel && !leftMenu }"
          >
            {{ $t(item.title) }}
          </div>
        </v-card>
      </v-col>
      <v-col
        :class="cardPadding"
        cols="12"
        :sm="leftMenu ? '12' : '6'"
        :md="leftMenu ? '12' : '4'"
      >
        <v-card
          class="d-flex elevation-0 rounded-sm pa-3 col-gap logout"
          @click="doLogout"
        >
          <div class="d-flex">
            <img height="44" src="/img_layout/dashboard/logout.svg" />
          </div>
          <div
            class="text-body-0 primary--text font-weight-bold align-self-center py-3"
            :class="{ 'py-3': !leftMenu }"
          >
            {{ $t("dashboard.logout") }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.dashboard {
  .logout {
    background-color: transparent;
  }
  .selected-item {
    border: 2px solid $primary;
  }
  .v-list-item__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 50px;
    margin-right: 16px;
  }
  .col-gap {
    column-gap: 15px;
  }
  .switch-wraper {
    width: fit-content;
  }

  .v-input--selection-controls.v-input--dense
    .v-input--selection-controls__ripple {
    display: none !important;
  }
  h2 {
    font-size: 27px;
  }
  h3 {
    font-size: 22px;
  }
}
.dot {
  height: 14px;
  width: 14px;
  border-radius: 50% !important;
  display: inline-block;
  align-self: center;
}
</style>
<script>
// @ is an alias to /src
import RegistrationService from "~/service/userService";
import TigrosRegistrationService from "@/service/tigrosRegistrationService";
import TigrosCardOroService from "@/service/tigrosCardOroService";
import get from "lodash/get";
import tigrosMixins from "@/mixins/tigrosMixins";
import recaptcha from "~/mixins/recaptcha";

import { mapActions } from "vuex";
import leafletService from "@/commons/service/leafletService";

export default {
  name: "Profile",

  mixins: [tigrosMixins, recaptcha],
  props: {
    leftMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: null,
      leaflet: null,
      listItems: [],
      secondListItems: [],
      baseItems: [
        {
          name: "profile",
          title: "dashboard.profile",
          icon: "dati",
          routePath: "/profile/profile-summary"
        },
        {
          name: "becomeTCO",
          title: "profile.menu.becomeTco",
          icon: "carta-oro",
          routePath: "/card-oro/become-tco"
        },
        {
          name: "cartaOro",
          title: "dashboard.cartaOro",
          icon: "carta-oro",
          routePath: "/card-oro/perks"
        },
        {
          name: "volantinoCartaOro",
          title: "dashboard.volantinoCartaOro",
          icon: "volantino-carta-oro"
        },
        {
          name: "lists",
          title: "dashboard.lists",
          icon: "liste",
          routePath: "/profile/lists"
        },
        {
          name: "orders",
          title: "dashboard.orders",
          icon: "ordini",
          routePath: "/profile/orders"
        },
        {
          name: "receipts",
          title: "dashboard.receipts",
          icon: "garanzie",
          routePath: "/profile/receipts"
        },
        {
          name: "digitalReceipt",
          title: "dashboard.digitalReceipt",
          icon: "garanzie",
          routePath: "/profile/digital-receipts",
          activeLabel: "common.active",
          inactiveLabel: "common.inactive",
          switchValue: false,
          switchLoading: false,
          promovar: "promovar_89555"
        },
        {
          name: "selfSpending",
          title: "dashboard.selfSpending",
          icon: "spesaself",
          routePath: "/profile/spesa-self",
          activeLabel: "common.active",
          inactiveLabel: "common.inactive",
          switchValue: false,
          switchLoading: false,
          promovar: "promovar_777777"
        },
        {
          name: "melaleggo",
          title: "dashboard.melaleggo",
          icon: "melaleggo",
          routePath: "/profile/melaleggo",
          activeLabel: "dashboard.melaleggoActive",
          // inactiveLabel: "dashboard.melaleggoInactive",
          switchValue: false,
          switchLoading: false,
          promovar: "promovar_1100"
        },
        {
          name: "tigrosCard",
          title: "dashboard.tigrosCard",
          icon: "tigros-card",
          routePath: "/profile/tigros-card"
        },
        // {
        //   name: "invite",
        //   title: "dashboard.invite",
        //   icon: "invita-un-amico",
        //   routePath: "/profile/invite"
        // },
        {
          name: "ratings",
          title: "profile.menu.ratings",
          icon: "ratings",
          routePath: "/profile/ratings"
        },
        {
          name: "customerService",
          title: "dashboard.customerService",
          icon: "servizio-clienti",
          routePath: "/profile/ticket"
        }
      ]
    };
  },
  async created() {
    await this.fetchUserData();
    this.updatedDrawerLeft(false);
  },
  computed: {
    isBAFEnabled() {
      // return this.user.betaTester === "1";
      return true;
    },
    cardPadding() {
      return {
        "pa-2": this.leftMenu,
        "pa-3": !this.leftMenu,
        "px-3 py-1": this.$vuetify.breakpoint.name === "xs"
      };
    },
    showSwitch() {
      return !this.leftMenu && !(this.$vuetify.breakpoint.name === "xs");
    },
    crmUserSegment() {
      return this.user.crmUserSegments;
    }
  },
  methods: {
    async fetchUserData() {
      let user = await RegistrationService.getUserDetail();
      let coudlBecomeTco = false;
      // if (!this.isCartaOroUser) {
      coudlBecomeTco = get(
        await TigrosCardOroService.getShowItemMenu(),
        "value.show_item",
        false
      );
      //fetch leaflet
      let leaflets = await leafletService.search({
        parent_leaflet_type_id: 2
      });
      if (leaflets.length > 0) {
        this.leaflet = leaflets[0];
      }

      // }
      this.user = user;
      this.baseItems.forEach(i => {
        switch (i.name) {
          case "becomeTCO":
            if (coudlBecomeTco) {
              this.listItems.push(i);
            }
            break;
          case "cartaOro":
            if (this.isCartaOroUser) {
              this.listItems.push(i);
            }
            break;
          case "volantinoCartaOro":
            if (this.leaflet && this.isCartaOroUser) {
              i.routePath = `/volantini/${this.leaflet.leafletId}`;
              this.listItems.push(i);
            }
            break;
          case "invite":
            if (this.isBAFEnabled) {
              this.listItems.push(i);
            }
            break;
          case "customerService":
            this.secondListItems.push(i);
            break;
          case "selfSpending":
          case "melaleggo":
          case "digitalReceipt":
            i.switchValue = this.isActive(i.promovar);
            this.listItems.push(i);
            break;
          default:
            this.listItems.push(i);
        }
      });
    },
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      loadCart: "cart/loadCart"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "HomeSite",
        path: "/"
      });
    },
    getCrm(promovar) {
      return this.crmUserSegment.find(e => e.crmSegment.code == promovar);
    },
    isActive(promovar) {
      let crm = this.getCrm(promovar);
      if (crm && crm.value) {
        return crm.value == 1;
      } else return false;
    },
    async switchState(name, active, promovar) {
      let index = this.baseItems.findIndex(item => {
        return name === item.name;
      });
      this.baseItems[index].switchLoading = true;
      let response = await TigrosRegistrationService.updatePromovar(
        promovar,
        active ? 0 : 1
      );
      if (
        response.data &&
        response.data.value &&
        response.data.value.success === true
      ) {
        this.baseItems[index].switchValue = !active;
        this.loadCart();
      }
      this.baseItems[index].switchLoading = false;
    }
  }
};
</script>
